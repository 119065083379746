<template>
  <div>
    <dashboard-page-title :showMainActions="false" :showSearch="false" :showFilter="false">العملاء</dashboard-page-title>

    <main-table :baseURL="VUE_APP_MARKETPLACE_LINK"  :fields="fields" list_url="admin/customers"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_MARKETPLACE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_MARKETPLACE_LINK,
      fields: [
        { label: 'صوره العميل', key: 'avatar', type: 'image', class: 'text-right' },
        { label: 'الاسم', key: 'name', class: 'text-right' },
        { label: 'رقم الهاتف', key: 'phone', class: 'text-right' },
        { label: 'البريد الالكترونى', key: 'email', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'التاريخ', key: 'created_at', class: 'text-right' }
      ]
    }
  },
  methods: {

  }
}
</script>
